<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color:red"
          >契約(ペレット)―削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status=='error'">
              <div
                v-for="msg of apierror.messages"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cont_id"
                  label="契約ID"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cont_name"
                  label="契約名"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cust_id"
                  label="取引先ID"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cont_date"
                  label="契約日"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cont_weight"
                  label="契約重量"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="selectedContract.cont_date"
                  label="契約金額"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <v-data-table
                  ref="Contracts"
                  class="class-on-data-table elevation-1"
                  :headers="contractHeaders"
                  :items="selectedContract.contract_details"
                  fixed-header
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="deleteStatus"
            @click="deleteItem(selectedContract)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  props: ['icon', 'contId'],
  data: () => ({
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    contractHeaders: [
      {
        text: 'No.',
        value: 'disp_order',
        sortable: false,
        align: 'left',
        width: '3%',
        fixed: true,
      },
      {
        text: '商品ID',
        value: 'item_id',
        sortable: false,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '契約商品名',
        value: 'item_name',
        sortable: false,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '重量(kg)',
        value: 'cont_dtl_weight',
        sortable: false,
        align: 'right',
        width: '15%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'details',
        sortable: false,
        align: 'center',
        width: '2%',
        fixed: true,
      },
      {
        text: '契約金額',
        value: 'cont_dtl_amount',
        sortable: false,
        align: 'right',
        width: '15%',
        fixed: true,
      },
      {
        text: '備考',
        value: 'item_note',
        sortable: false,
        align: 'center',
        width: '27%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '2%',
        fixed: true,
      },
    ],
    deleteStatus: false,
  }),
  computed: {
    ...mapState('contractStore', ['selectedContract']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    openForm() {
      // this.dialog = true
      this.setOverlayStatus(true)
      this.loadContract(this.contId)
        .catch(error => {
          this.apierror.status = 'error'
          if (error.response) {
            this.apierror.messages = error.response.data.message
          }

          // this.apierror.messages.push('削除できません。')
          console.log('apierror', this.apierror)
        }).finally(() => {
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
    closeForm() {
      this.$emit('closeForm')
      this.apierror.messages = []
      this.dialog = false
    },
    ...mapActions('contractStore', ['deleteContract', 'loadContract']),
    deleteItem(selectedContract) {
      this.apierror.messages = []
      this.deleteStatus = true
      this.deleteContract(selectedContract.id).then(() => {
        this.dialog = false
      }).catch(error => {
        this.deleteStatus = false
        this.apierror.status = 'error'
        if (error.response) {
          this.apierror.messages = error.response.data.message
        }

        console.log('apierror', this.apierror)
      }).finally(() => {
        this.deleteStatus = false
        this.$emit('closeForm')
      })
    },
  },

}
</script>
