<template>
  <div id="contrated-list">
    <v-card>
      <v-card-title>契約商品(一覧)</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="contract_id"
            placeholder="契約ID"
            :items="contractIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col> -->
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="product_id"
            placeholder="Pellet"
            :items="productIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="product_id"
            placeholder="予約商品"
            :items="preorderIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="product_id"
            placeholder="入札商品"
            :items="auctionIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <!-- <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="contract_status"
            :items="contractStatuses"
            placeholder="契約状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col> -->
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'contract-create', params: { query: this.$route.query } }"
          >
            <span>NEW</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="contractList"
        class="class-on-data-table elevation-1"
        :headers="headers"
        :items="contractList"
        item-key="cd_id"
        :search="searchQuery"
      >
        <template v-slot:[`item.contract_status`]="{ item }">
          <label>{{
            contractStatus(item)
          }}</label>
        </template>
        <template v-slot:[`item.cont_weight`]="{ item }">
          <label>{{ formatData(item.cont_weight) }}</label>
        </template>
        <template v-slot:[`item.cont_amount`]="{ item }">
          <label>{{ formatData(item.cont_amount) }}</label>
        </template>
        <template v-slot:[`item.quot_code`]="{ item }">
          <v-icon class="mr-2" @click="previewItem(item)">
            {{ icons.mdiFilePdfBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item.cont_id)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <contract-delete
              :icon="icons.mdiDelete"
              :cont-id="item.cont_id"
              class="py-2 pl-1"
              @closeForm="loadData(false)"
            ></contract-delete>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import { mdiPencil, mdiDelete, mdiExportVariant, mdiCalendar, mdiMinus, mdiPlus, mdiFilePdfBox } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import ContractDelete from './ContractDelete.vue'
/* eslint-disable */
export default {
  components: {
    ContractDelete,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiFilePdfBox,
      mdiCalendar,
      mdiMinus,
      mdiPlus,
    },
    dateEndMenu: '',
    dateStartMenu: '',
    contract_status: '',
    contractStatuses: [
      { text: '仮契約', value: '1' },
      { text: '契約済', value: '2' },
      { text: '出荷可', value: '3' },
      { text: '出荷済', value: '4' },
      { text: '取消', value: '9' },
    ],
    searchBar: {},
    searchQuery: '',
    product_id: '',
    contract_id: '',
    customer_id: '',
    startDate: '',
    endDate: '',
    isExpand: false,
    productIds: [],
    preorderIds: [],
    auctionIds: [],
    headers: [
      {
        text: 'ID.',
        value: 'cont_id',
        align: 'left',
        width: '7%',
        fixed: true,
      },
      // {
      //   text: '契約番号',
      //   value: 'cont_code',
      //   align: 'left',
      //   width: '10%',
      //   fixed: true,
      // },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約名',
        value: 'cont_name',
        align: 'left',
        width: '27%',
        fixed: true,
      },
      {
        text: '契約日',
        value: 'cont_date',
        align: 'left',
        width: '13%',
        fixed: true,
      },

      {
        text: '契約重量(kg)',
        value: 'cont_weight',
        align: 'right',
        width: '15%',
        sortable: true,
        fixed: true,
      },
      {
        text: '契約金額',
        value: 'cont_amount',
        align: 'right',
        width: '15%',
        sortable: true,
        fixed: true,
      },
      {
        text: 'PDF',
        value: 'quot_code',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '8%',
        fixed: true,
      },
    ],
  }),

  computed: {

    ...mapState('customerStore', ['customerIds']),
    ...mapState('contractStore', ['pelletContracts', 'f_contracts', 'contractIds', 'contractList']),
    sumWgt() {
      let sum = 0
      this.contractList.forEach(a => {
        if (a.product_id === this.product_id) {
          sum += a.contract_weight
        }
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    contractStatus() {
      return function (item) {
        const status = this.contractStatuses.find(cs => cs.value === item.contract_status.toString())

        return status.text ?? ""
      }
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  watch: {
    contract_id(newValue) {
      this.changeRouteQuery('cont_id', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    product_id(newValue) {
      this.changeRouteQuery('product_id', newValue)
      if (newValue) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    customer_id(newValue) {
      this.changeRouteQuery('cust_id', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    contract_status(newValue) {
      this.changeRouteQuery('contract_status', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    // this.loadContractIds()
    // this.loadProductIds()
    // this.loadCustomerIds()
     this.loadData(false)
  },

  methods: {

    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('contractStore', ['loadContracts']),
    ...mapMutations('app', ['setOverlayStatus']),

    previewItem(item)
    {
      console.log('view', item)
      this.$router.push({
        name: 'contract-preview',
        params: {
          id: item.cont_id,
        },
      })
    },

    loadData(status) {
      this.setOverlayStatus(true)
      this.loadContracts(this.$route.query).finally(() => {
        console.log('contractList', this.contractList)
        this.setOverlayStatus(false)
      })
    },
    editItem(contractId) {
      console.log(contractId)

      this.$router.push({ name: 'contract-edit', params: { id: contractId, query: this.$route.query } })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({ path: this.$route.path, query })
    },
    loadQuery() {
      this.contract_id = this.$route.query.cont_id ?? ''
      this.product_id = this.$route.query.product_id ?? ''
      this.customer_id = this.$route.query.cust_id ?? ''
      this.contract_status = this.$route.query.contract_status ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    downloadCSV() {

      // eslint-disable-next-line no-useless-concat
      let csv = '\ufeff' + 'No.,契約ID,契約日,取引先ID,契約名,ペレットID,重量(kg),契約単価,備考\n'
      let no = 0
      this.contractList.forEach(el => {
        no += 1
        const contract_id = "CP-" + el.contract_id +"-" + el.detail_id
        const contract_date = el.contract_date ?? ""
        const customer_id = el.customer_id ?? ""
        const contract_name = el.contract_name ?? ""
        const product_id = el.product_id ?? ""
        const contract_weight = el.contract_weight ? el.contract_weight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const contract_price = el.contract_price ? el.contract_price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const note  = el.contract_note ?? ""
        const line = `"${no}","${contract_id}","${contract_date}","${customer_id}","${contract_name}","${product_id}","${contract_weight}","${contract_price}","${note}"\n`
        csv += line
      })
      const blob = new Blob([csv], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      const newstr = nowstr.replace(/(-|:|T)/g, '')
      link.download = `ペレット契約一覧-${newstr}.csv`
      link.click()
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
tr {
  height: 50px;
  font-size: 14px;
}
td {
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
  padding: 0px 16px;
}
</style>
